import './ProgressBar.scss'
import ScrollMagic from 'scrollmagic';
import { useEffect } from 'react';

export const ProgressBar = (props) =>{

    const style = {
        width: props.progress
    }

 


    // useEffect(() => {
    //     const controller = new ScrollMagic.Controller();

    //     new ScrollMagic.Scene({
    //         triggerElement: '#' + props.skill,
    //     })
    //     .setClassToggle('#' + props.skill, 'showBar')
    //     .addTo(controller)
    // }
    // )

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#HTML', 'showHTML')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#JavaScript', 'showJS')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#React', 'showReact')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#AdobeCreativeCloud', 'showAdobe')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Laravel', 'showLaravel')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Docker', 'showDocker')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#CSS', 'showCSS')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Scrum', 'showScrum')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Angular', 'showAngular')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Node', 'showNode')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#Git', 'showGit')
        .addTo(controller)

        new ScrollMagic.Scene({
            triggerElement: '#Scrum',
        })
        .setClassToggle('#SEO', 'showSEO')
        .addTo(controller)
    }
    )
    

    return(
        <div className="progress-wrapper" >
            <div className="progress" id={props.skill} style={style}>
            <span>{props.skill}</span>
            </div>
        </div>
 
    )
}