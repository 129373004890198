import './Contact.scss';
import { useForm } from '@formspree/react';
import githubIcon from '../../assets/github_logo.svg';
import linkedinIcon from '../../assets/linkedin-logo.svg';

export const Contact = () => {

    const [state, handleSubmit] = useForm("mjvlvopo");

    if (state.succeeded) {
        return (
        <div className="contact-wrapper">
        <span className="formSucces">Your message has been succesfully sent!</span>
        <div className="form">
        <h3>Contact</h3>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input name="email" type="email" id="email"/>
            <label htmlFor="subject">Subject</label>
            <input name="subject" type="text" id="subject"/>
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" />
            <button type="submit">SEND</button>
        </form>
        </div>
        
        <div className="contactLinks">
        <a href="https://github.com/ThomasVanGheluwe" target="_blank" rel="noreferrer"><img id="githubIcon" src={githubIcon} alt="Github Logo" /></a>
        <a href="https://www.linkedin.com/in/thomas-van-gheluwe/" rel="noreferrer"><img id="linkedInIcon" src={linkedinIcon} alt="LinkedIn Logo"/></a>
        </div>
        </div>
        )
       
    }

    return(
        <div className="contact-wrapper">
        <div className="form">
        <h3>Contact</h3>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input name="email" type="email" id="email"/>
            <label htmlFor="subject">Subject</label>
            <input name="subject" type="text" id="subject"/>
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" />
            <button type="submit">SEND</button>
        </form>
        </div>
        
        <div className="contactLinks">
        <a href="https://github.com/ThomasVanGheluwe" target="_blank" rel="noreferrer"><img id="githubIcon" src={githubIcon} alt="Github Logo" /></a>
        <a href="https://www.linkedin.com/in/thomas-van-gheluwe/" target="_blank" rel="noreferrer"><img id="linkedInIcon" src={linkedinIcon} alt="LinkedIn Logo"/></a>
        </div>
        </div>
    )
}