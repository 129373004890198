import './HomeHeader.scss';

import Particles from "react-tsparticles";
import downArrow from '../../assets/downArrow.svg';

export const HomeHeader = () => {
    return(
        <div className="homeHeader">
    <Particles
      id="tsparticles"
      options={{
        background: {
          color: {
            value: "#00000",
          },
        },
        fpsLimit: 30,
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "bubble",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 150,
              duration: 2,
              opacity: 0.8,
              size: 20,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 160,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 900,
            },
            value: 50,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "triangle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
            <div className="intro">
            <h1>Thomas Van Gheluwe</h1>
            <h3>front-end web developer</h3>
            </div>

            <div className="continue">
            <a href="#about"><img src={downArrow} alt=""/></a>
            
            </div>
            
        </div>
    )
}

