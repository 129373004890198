import './About.scss';
import avatar from '../../assets/avatar.jpg'
import ScrollMagic from 'scrollmagic';
import { useEffect } from 'react';

export const About = () =>{

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '#about',
        })
        .setClassToggle('.about-wrapper', 'show')
        .addTo(controller)
    }
    )

    return(
        <div className="about-wrapper" id="about">
            <div className="avatar">
                <img src={avatar} alt="" />
            </div>

            <div className="intro-text">
                <h3>About me</h3>

                <p>I am a front end web developer based
                in Brussels who is passionate about <span>design </span>
                & <span>code</span>. 
                </p>

                <p>I graduated with a bachelor's degree in
                Multimedia & Creative Technology from
                <span> Erasmus Hogeschool Brussel</span>.
                </p>
            </div>
        </div>
    )
}