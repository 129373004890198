import {Helmet} from "react-helmet";

export const SEO = () => {
    return(
    <Helmet>
        {/* HTML Meta Tags*/}
        <title>Thomas Van Gheluwe</title>
        <meta name="description" content="My portfolio, it contains some of the works I did during my bachelor in Multimedia & Creative Tools at Erasmus Hogeschool Brussel."/>
         {/* Google / Search Meta Tags*/}   
        <meta itemprop="name" content="Thomas Van Gheluwe | Portfolio"/>
        <meta itemprop="description" content="My portfolio, it contains some of the works I did during my bachelor in Multimedia & Creative Tools at Erasmus Hogeschool Brussel."/>
        <meta itemprop="image" content=""/>

        {/* Facebook Meta Tags*/}
        <meta property="og:url" content="http://thomasvangheluwe.be"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Thomas Van Gheluwe | Portfolio"/>
        <meta property="og:description" content="My portfolio, it contains some of the works I did during my bachelor in Multimedia & Creative Tools at Erasmus Hogeschool Brussel."/>
        <meta property="og:image" content=""/>

        {/* Twitter Meta Tags*/}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Thomas Van Gheluwe | Portfolio"/>
        <meta name="twitter:description" content="My portfolio, it contains some of the works I did during my bachelor in Multimedia & Creative Tools at Erasmus Hogeschool Brussel."/>
        <meta name="twitter:image" content=""/>
    </Helmet>
    )
}