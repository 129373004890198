import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { motion, AnimatePresence } from "framer-motion";
import { Content } from "./Content";
import "./Three.scss";



export const Three = () => {

    const work = 
        {
            title: "Three.js Demo",
            tag: ["3D","Development"],
            description: ["For my school specialization in web development, I made a demo in three.js.",
            "I wanted to learn working with three.js in regards to my Final Work.","3D model made by Loïc Norgeot."],
            link: "https://sketchfab.com/3d-models/of-planes-and-satellites-db8f1c8cba3b464993e216acbf4a69b9",
            linkTag: "Link to 3D model"
        }
    

    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    }

    const CameraController = () => {
      const { camera, gl } = useThree();
      useEffect(
         () => {
            const controls = new OrbitControls(camera, gl.domElement);
            controls.touches.ONE = THREE.TOUCH.PAN;
            controls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
            controls.enableZoom = true;
            return () => {
              controls.dispose();
            };
         },
         [camera, gl]
      );
      return null;
    };

    return(
        <motion.li layout  onClick={toggleOpen}>
            <motion.div className="three-object">
                <Canvas>
                <ambientLight intensity={0.8} />
                <CameraController/>
                <Suspense fallback={null}>
                <Model />
                </Suspense>
                </Canvas> 
            </motion.div>
            <AnimatePresence>{open && <Content title={work.title} info={work.description} tag={work.tag} link={work.link} linkTag={work.linkTag}/>}</AnimatePresence>
        </motion.li>
    )
  }


  export default function Model({ ...props }) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/scene.gltf')
    useFrame(() => (group.current.rotation.y += 0.01))
    return (
      <group ref={group} {...props} dispose={null}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group scale={0.5}>
            <group rotation={[Math.PI / 2, 0, 0]}>
              <lineSegments geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
              <lineSegments geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
              <lineSegments geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
              <lineSegments geometry={nodes.mesh_3.geometry} material={nodes.mesh_3.material} />
              <lineSegments geometry={nodes.mesh_4.geometry} material={nodes.mesh_4.material} />
              <lineSegments geometry={nodes.mesh_5.geometry} material={nodes.mesh_5.material} />
              <mesh geometry={nodes.mesh_6.geometry} material={materials.earth} />
            </group>
          </group>
        </group>
      </group>
    )
  }
  
  useGLTF.preload('/scene.gltf');