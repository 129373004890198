import { motion } from "framer-motion";
import "./Content.scss";

export const Content = (props) => {
    return (
        <motion.div
        className="work-content"
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{duration: 0.2}}
          exit={{ opacity: 0 }}
        >
          <h4>{props.title}</h4>
          {props.info.map(element => (<p>{element}</p>))}
          <div className="tag-wrapper">
          {props.tag.map(element => (<span>{element}</span>))}
          </div>
          <a href={props.link} target='_blank' rel="noreferrer">{props.linkTag}</a>
        </motion.div>
      );
}
