import { motion, AnimateSharedLayout } from "framer-motion";
import { Item } from "./Item";
import Redesign from '../../assets/2dehandsMockup-min.png';
import Junto from '../../assets/Junto_Mockup-min.png';
import BassInvasion from '../../assets/mockupBassInvasion-min.png';
import VueDemo from '../../assets/vue_project.png';
import Vitrine from '../../assets/vitrine.png';
import "./Work.scss";
import ScrollMagic from 'scrollmagic';
import { useEffect } from 'react';
import { Three } from "./Three";
// import { Discord } from "./Discord";

export const Work = () => {

    const works = [
        {
            title: "Vue.js Demo",
            tag: ["Demo","Development"],
            description: ["In my specialization course, I made a basic Vue application.",
            "In this app a list of the current top 100 movies get fetched from the IMDb API.",
            "The user can give a rating to a movie and then see if his rating is higher than IMDb's rating"
            ],
            image: VueDemo,
            link: "https://priceless-austin-73e5bb.netlify.app",
            linkTag: "Open website"
        },
        {
            title: "EHB Vitrine",
            tag: ["GroupSchoolProject","Development"],
            description: ["During my specialization course me and my co-students created a web app for the student of Erasmus Hogeschool Brussel.",
            "In this app, students can post all of their work. The app will also be used for the Final Show of EHB.",
            "I was part of the front-end team."
            ],
            image: Vitrine,
            link: "https://www.vitrine-finalshow.be/",
            linkTag: "Open website"
        },
        {
            title: "2dehands redesign",
            tag: ["IndividualSchoolProject","Design","Development"],
            description: ["For the school subject: Frontend, I had to redesign the 2dehands website and afterwards develop my design in Angular.",
            "First I had to analyse the existing website and note down all the bad design practices.", "Afterwards it was time to convert my design into an Angular application."],
            image: Redesign,
            link: "https://elated-murdock-72ac59.netlify.app",
            linkTag: "Open website"
        },
        {
            title: "Website redesign",
            tag: ["GroupSchoolProject","Development"],
            description: ["During a three week group project, we had to rebrand a non-profit organisation: Gehandicapten en Solidariteit.", 
            "We chose for a refreshing and neutral name: Junto.", "For the website we kept their main color red and we completely redesigned their website based on their target audience.", 
            "We opted for a clean, minimalistic and easy to use design. The website is also responsive for mobile, tablet and pc."],
            image: Junto,
            link: "https://vzw-junto.netlify.app/",
            linkTag: "Open website"
        },
        {
            title: "Design fictional festival",
            tag: ["IndividualSchoolProject","Design"],
            description: ["For a school subject: Design 2, I had to create a mobile design for a fictional festival.", 
            "In the exercise, I chose to create my ultimate dream festival: Bass Invasion.", "For the style of my design, I chose a glitchy neon theme.", 
            "I am really happy with the result of my design."],
            image: BassInvasion,
            link: "https://xd.adobe.com/view/8ef55b89-d616-4a70-91db-b97a0eed0b90-dba6/",
            linkTag: "Open interactive prototype"
        },
    ]

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '#works',
        })
        .setClassToggle('.work-wrapper', 'show')
        .addTo(controller)
    }
    )

    return(
        <div className="work-wrapper" id="works">
            <div id="workTitle">
            <h3>My work</h3>
            </div>
            <div className="work-items">
            
            <AnimateSharedLayout>
                <motion.ul layout>
                <span className="item-details">Click on a project to see more info!</span>
                {/* <Discord/> */}
                {/* <Three/> */}
                {works.map(element => (
                    <Item key={element} title={element.title} info={element.description} image={element.image} tag={element.tag} link={element.link} linkTag={element.linkTag}/>
                ))}
                </motion.ul>
            </AnimateSharedLayout>
            </div>
        </div>
    )
}
