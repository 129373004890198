import './App.css';
import { useState, useEffect } from 'react';
import { About } from './components/About/About';
import { Contact } from './components/Contact/Contact';
import { HomeHeader } from './components/HomeHeader/HomeHeader';
import { Skills } from './components/Skills/Skills';
import { Work } from './components/Work/Work';
import loader from './assets/loading.gif'
import { SEO } from './components/SEO/SEO';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2000);
    return () => clearTimeout(timer);
  },
  []);

  return (
    loading ? (
    <div class="loader"><h3>Loading Portfolio</h3><img src={loader} alt="" srcset="" /></div>
    ) : (
    <div className="App">
    <SEO/>
    <HomeHeader />
    <div id="about"></div>
    <About />
    <Skills />
    <Work />
    <Contact />
    </div>)
  );
}

export default App;
