import './Skills.scss'
import { ProgressBar } from './ProgressBar';
import ScrollMagic from 'scrollmagic';
import { useEffect } from 'react';

export const Skills = () =>{         
    
    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '#skills',
        })
        .setClassToggle('.skills-wrapper', 'show')
        .addTo(controller)
    }
    )

    return(
        <div className="skills-wrapper">
            <h3 id="skills">My skills</h3>
            <div className="progressbar-wrapper">
                <div>
                <ProgressBar skill="Scrum" progress="92%"/>
                <ProgressBar skill="CSS" progress="90%"/>
                <ProgressBar skill="HTML" progress="90%"/>
                <ProgressBar skill="Git" progress="80%"/>
                <ProgressBar skill="JavaScript" progress="75%"/>
                <ProgressBar skill="Node" progress="75%"/>
                </div>

                <div>
                <ProgressBar skill="React" progress="75%"/>
                <ProgressBar skill="AdobeCreativeCloud" progress="70%"/>
                <ProgressBar skill="Docker" progress="65%"/>
                <ProgressBar skill="Angular" progress="65%"/>
                <ProgressBar skill="SEO" progress="62%"/>
                <ProgressBar skill="Laravel" progress="55%"/>
                </div>
            </div>
        </div>
    )
}