import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Item.scss";
import { Content } from "./Content";

export const Item = (props) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    }

    return(
        <motion.li layout  onClick={toggleOpen}>
            <motion.img className="workImage" src={props.image} layout/>
            <AnimatePresence>{open && <Content title={props.title} info={props.info} tag={props.tag} link={props.link} linkTag={props.linkTag} />}</AnimatePresence>
        </motion.li>
    )
}
